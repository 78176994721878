const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://92z6avg08g.execute-api.us-west-2.amazonaws.com/prod',
    TARGET_API_URL: 'https://8nqar57smc.execute-api.us-west-2.amazonaws.com/api',
    TEAMS_APP_URL: 'https://teams.testing.newcrest.forwoodsafety.com/teams',
    QS_DASHBOARD_URL: '',
    CLIENT_TIMEZONE: 'America/Sao_Paulo'
  },
  reactApp: {
    VERSION: 'targets-1.7.0',
    HOSTNAME: 'https://targets.testing.newmont.forwoodsafety.com'
  }
};

export default config;